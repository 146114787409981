import React from "react";

export default function Title() {
  return (
    <>
      <div className="title">
        <h2>RECEIPT</h2>
      </div>
    </>
  );
}
