import React from "react";

export default function Header() {
  return (
    <>
      <div className="header">
        <div className="logo">
          <img
            className="t-logo"
            src={require("./assets/logot.jpeg")}
            alt="Teestyleshomes logo"
          />
        </div>
        <div className="contact">
          <p>
            <img src={require("./assets/location.jpeg")} alt="location sign" />
            51 Oladimeji Str. Surulere Lagos
          </p>
          <p>
            <span className="what">
              <img src={require("./assets/whatsapp.png")} alt="whatsapp sign" />
              09038244671
            </span>
            <span className="ig">
              <img src={require("./assets/ig.jpg")} alt="instagram sign" />
              Teestyleshomes
            </span>
          </p>
          <p>
            <img src={require("./assets/email.jpeg")} alt="email sign" />
            mamifisebiadejagun@yahoo.com
          </p>
        </div>
      </div>
    </>
  );
}
