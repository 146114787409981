import React from "react";

export default function LastContainer() {
  return (
    <>
      <div className="last-container">
        <div className="first-last-container">
          <p>Thank you for choosing</p>
          <p>
            <strong>TEESTYLESHOMES.</strong>
          </p>
          <p className="lifestyle">
            <strong>EXPERIENCE THE LIFESTYLE.</strong>
          </p>
        </div>
        <div className="second-last-container">
          <div className="title2">
            <h4>TERMS AND CONDITIONS</h4>
          </div>
          <ul>
            <li>
              <strong>Smoking not allowed in the apartment</strong>
            </li>
            <li>
              <strong>No noise/disturbance in the estate</strong>
            </li>
            <li>
              <strong>Cancellation fee 30%</strong>
            </li>
            <li>
              <strong>Not exceeding 7 guests in the apartment</strong>
            </li>
            <li>
              <strong>
                All payments are non-refundable apart from the caution fee which
                takes 24 hours after check out.
              </strong>
            </li>
            <li>
              <strong>
                Check in time is by 2pm and check out time is by 12pm.
              </strong>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
