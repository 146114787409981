// Read thoroughly for comments
// Apart from the subtotal, VAT, and Grandtotal I don't think there's any more changes to be made except you want to make any personal changes

import React from "react";
import { useState, useEffect, useRef } from "react";
import SubContainer from "./components/SubContainer";
import Header from "./components/Header";
import Title from "./components/Title";
import HeadContainer from "./components/HeadContainer";
import FirstBodyContainer from "./components/FirstBodyContainer";
import SecondBodyContainer from "./components/SecondBodyContainer";
import LastContainer from "./components/LastContainer";
import Footer from "./components/Footer";

export default function Container() {
  const [showReceipt, setShowReceipt] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [receiptDate, setReceiptDate] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [description, setDescription] = useState("");
  const [nightNumber, setNightNumber] = useState(0);
  const [nightPrice, setNightPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [cautionPrice, setCautionPrice] = useState(0);
  const [cautionTotal, setCautionTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [vat, setVat] = useState(12.5);
  const [grandTotal, setGrandTotal] = useState("");

  const componentRef = useRef();

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    const calculateAmount = () => {
      return nightNumber * nightPrice;
    };

    const result = calculateAmount();
    setAmount(result);
  }, [nightNumber, nightPrice]);

  useEffect(() => {
    const newTotal = parseFloat(amount) + parseFloat(cautionPrice);

    setCautionTotal(cautionPrice);
    setSubTotal(newTotal || 0);
  }, [cautionPrice, amount]);

  useEffect(() => {
    const calculateVat = () => {
      const vatAmount = subTotal * 0.125;
      return vatAmount;
    };

    const vatResult = calculateVat();
    setGrandTotal(vatResult.toFixed(2));
  }, [subTotal]);

  return (
    <>
      <div className="container">
        {showReceipt ? (
          <>
            <div className="new-container" ref={componentRef}>
              <SubContainer />
              <Header />
              <Title />
              <hr className="first-hr" />
              <HeadContainer
                receiptNumber={receiptNumber}
                name={name}
                checkIn={checkIn}
                checkOut={checkOut}
                receiptDate={receiptDate}
              />
              <FirstBodyContainer />
              <SecondBodyContainer
                description={description}
                address={address}
                nightNumber={nightNumber}
                nightPrice={nightPrice}
                amount={amount}
                cautionPrice={cautionPrice}
                cautionTotal={cautionTotal}
                subTotal={subTotal}
                vat={vat}
                grandTotal={grandTotal}
              />
              <LastContainer />
            </div>
            <div className="footer-container">
              <Footer
                handlePrint={handlePrint}
                setShowReceipt={setShowReceipt}
                componentRef={componentRef}
              />
            </div>
          </>
        ) : (
          // This is the default form

          <>
            <div className="form">
              <div className="form-body">
                <SubContainer />
                <Header />
                <div className="title">
                  <h2>RECEIPT GENERATOR</h2>
                </div>
                <hr className="second-hr" />
                <div className="main-form">
                  {/* These are the parts of the form
                  You are going to make changes in the sixth and seventh div with className of six and seven respectively */}

                  <div className="one">
                    <div className="in-cont">
                      <label htmlFor="receiptnumber">Receipt Number</label>
                      <input
                        type="text"
                        name="text"
                        id="receiptnumber"
                        placeholder="Enter receipt number"
                        autoComplete="off"
                        value={receiptNumber}
                        onChange={(e) => setReceiptNumber(e.target.value)}
                      />
                    </div>
                    <div className="in-cont">
                      <label htmlFor="name">Client Name</label>
                      <input
                        type="text"
                        name="text"
                        id="name"
                        placeholder="Enter client name"
                        autoComplete="off"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="two">
                    <div className="in-cont">
                      <label htmlFor="checkin">Check in date</label>
                      <input
                        type="date"
                        name="text"
                        id="checkin"
                        placeholder="Enter check in date"
                        autoComplete="off"
                        value={checkIn}
                        onChange={(e) => setCheckIn(e.target.value)}
                      />
                    </div>
                    <div className="in-cont">
                      <label htmlFor="checkout">Check out date</label>
                      <input
                        type="date"
                        name="text"
                        id="checkout"
                        placeholder="Enter check out date"
                        autoComplete="off"
                        value={checkOut}
                        onChange={(e) => setCheckOut(e.target.value)}
                      />
                    </div>
                    <div className="in-cont">
                      <label htmlFor="receiptdate">Receipt Date</label>
                      <input
                        type="date"
                        name="text"
                        id="receiptdate"
                        placeholder="Enter date of issue of receipt"
                        autoComplete="off"
                        value={receiptDate}
                        onChange={(e) => setReceiptDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="three">
                    <div className="in-cont">
                      <label htmlFor="description">Apartment Description</label>
                      <input
                        type="text"
                        name="text"
                        id="description"
                        placeholder="Enter apartment description"
                        autoComplete="off"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="in-cont">
                      <label htmlFor="address">Apartment Address</label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Enter apartment address"
                        autoComplete="off"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="four">
                    <div className="in-cont">
                      <label htmlFor="nightnumber">
                        Number of nights to be booked
                      </label>
                      <input
                        type="number"
                        name="text"
                        id="nightnumber"
                        placeholder="Enter number of nights"
                        autoComplete="off"
                        value={nightNumber}
                        onChange={(e) => setNightNumber(e.target.value)}
                      />
                    </div>
                    <div className="in-cont">
                      <label htmlFor="nightprice">Price per night</label>
                      <input
                        type="number"
                        name="text"
                        id="nightprice"
                        placeholder="Enter price"
                        autoComplete="off"
                        value={nightPrice}
                        onChange={(e) => setNightPrice(e.target.value)}
                      />
                    </div>
                    <div className="in-cont">
                      <label htmlFor="total">Total</label>
                      <p>{amount}</p>
                    </div>
                  </div>
                  <div className="five">
                    <div className="in-cont">
                      <label htmlFor="cautionprice">
                        Refundable Caution Fee
                      </label>
                      <input
                        type="number"
                        name="text"
                        id="cautionprice"
                        placeholder="Enter price"
                        autoComplete="off"
                        value={cautionPrice}
                        onChange={(e) => setCautionPrice(e.target.value)}
                      />
                    </div>
                    <div className="in-cont">
                      <label htmlFor="cautiontotal">Caution Fee Total</label>
                      <p>{cautionTotal}</p>
                    </div>
                  </div>
                  <div className="six">
                    <div className="in-cont">
                      <label htmlFor="subtotal">Sub-Total</label>
                      <p>{subTotal}</p>
                    </div>
                    <div></div>
                  </div>
                  <div className="seven">
                    <div className="in-cont">
                      <label htmlFor="vat">Enter VAT</label>
                      <input
                        // Im changing this to type="number" just in case

                        type="number"
                        name="text"
                        id="vat"
                        placeholder="Enter VAT"
                        autoComplete="off"
                        value={vat}
                        onChange={(e) => setVat(e.target.value)}
                      />
                    </div>

                    {/* After setting the grandtotal as sum of subtotal and VAT, you will change the html to look like this:
                    <div className="in-cont">
                      <label htmlFor="subtotal">Sub-Total</label>
                      <p>{subTotal}</p>
                    </div>
                     */}

                    <div className="in-cont">
                      <label htmlFor="grandtotal">Grand Total</label>
                      <input
                        type="number"
                        name="text"
                        id="grandtotal"
                        placeholder="Enter grand total price"
                        autoComplete="off"
                        value={grandTotal}
                        onChange={(e) => setGrandTotal(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="preview">
                  <button onClick={() => setShowReceipt(true)}>
                    Preview Receipt
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
