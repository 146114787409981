import React from "react";

export default function SecondBodyContainer({
  description,
  address,
  nightNumber,
  nightPrice,
  amount,
  cautionPrice,
  cautionTotal,
  subTotal,
  vat,
  grandTotal,
}) {
  return (
    <>
      <div className="second-body-container">
        <div className="apartment-info box width">
          <p className="apartment-type">{description}</p>
          <p className="apartment-address">{address}</p>
        </div>
        <p className="night-no">{nightNumber}</p>
        <p className="night-price">{nightPrice}</p>
        <p className="total">{amount}</p>
        <div className="box width">
          <p>REFUNDABLE CAUTION FEE</p>
        </div>
        <p></p>
        <p className="caution-price">{cautionPrice}</p>
        <p className="caution-total">{cautionTotal}</p>
        <p className="box"></p>
        <p></p>
        <p>Subtotal</p>
        <p className="subtotal">{subTotal}</p>
        <p className="box"></p>
        <p></p>
        <p>VAT</p>
        <p className="value">{vat}</p>
        <p className="box"></p>
        <p></p>
        <p>
          <strong>GRAND TOTAL</strong>
        </p>
        <p className="grandtotal">
          <strong>{grandTotal}</strong>
        </p>
      </div>
    </>
  );
}
