import React from "react";

export default function HeadContainer({
  receiptNumber,
  name,
  checkIn,
  checkOut,
  receiptDate,
}) {
  return (
    <>
      <div className="head-container">
        <div className="first-head-container">
          <p className="receipt-no">
            <strong>Receipt Number:</strong> #{receiptNumber}
          </p>
          <p>
            <strong>Receipt To:</strong>
          </p>
          <p className="client-name">
            <strong>{name}</strong>
          </p>
          <p className="check-in">
            <strong>Check in Date:</strong> {checkIn}
          </p>
          <p className="check-out">
            <strong>Check out Date:</strong> {checkOut}
          </p>
        </div>
        <div className="second-head-container">
          <p className="receipt-date">
            <strong>Receipt Date:</strong> {receiptDate}
          </p>
        </div>
      </div>
    </>
  );
}
