import React from "react";

export default function SubContainer() {
  return (
    <>
      <div className="sub-container">
        <div className="box1 small-box"></div>
        <div className="box2 small-box"></div>
      </div>
    </>
  );
}
