import React from "react";

export default function FirstBodyContainer() {
  return (
    <>
      <div className="first-body-container">
        <p className="box">Item Description</p>
        <p>Nights</p>
        <p>Price per Night (#)</p>
        <p>Total (#)</p>
      </div>
    </>
  );
}
