// Most of the code is in container.js
// the components for the receipt are in the component folder but the form is raw coded in container.js

import "./Receipt.css";
import Container from "./Container";

function App() {
  return (
    <>
      <Container />
    </>
  );
}

export default App;
