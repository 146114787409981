import React from "react";
import ReactToPrint from "react-to-print";

export default function Footer({ componentRef, setShowReceipt }) {
  return (
    <>
      <div className="footer">
        <button className="edit" onClick={() => setShowReceipt(false)}>
          Edit Information
        </button>
        <ReactToPrint
          trigger={() => <button className="print">Print</button>}
          content={() => componentRef.current}
        />
      </div>
    </>
  );
}
